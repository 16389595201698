//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from "@nuxtjs/composition-api";
const __sfc_main = {};
__sfc_main.props = {
  alt: {
    type: String,
    default: ""
  },
  background: Boolean,
  position: {
    type: String,
    default: null
  },
  sizes: {
    type: String,
    default: "xs:100vw sm:100vw md:100vw lg:100vw xl:100vw"
  },
  src: {
    type: String,
    default: null
  },
  srcDesktop: {
    type: String,
    default: null
  },
  srcMobile: {
    type: String,
    default: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    position
  } = __props;
  const style = computed(() => position ? {
    objectPosition: position
  } : null);
  return {
    style
  };
};

export default __sfc_main;
