//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose } from "@mdi/js";
import ContactForm from "./ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
  data() {
    return {
      mdiClose,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
