import Vue from "vue";

const handleClick = (el, { modifiers }, event) => {
  event.preventDefault();
  const destination = document.querySelector(el.getAttribute("href"));
  if (!destination) return;
  const scrollParams = modifiers.smooth ? { behavior: "smooth" } : {};
  destination.scrollIntoView(scrollParams);
};

const ScrollTo = {
  bind(el, binding) {
    el.addEventListener("click", handleClick.bind(this, el, binding));
  },
  unbind(el, binding) {
    el.removeEventListener("click", handleClick.bind(this, el, binding));
  },
};

Vue.directive("scroll-to", ScrollTo);
