import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('GlobalHeader'),_vm._v(" "),_c(VMain,[_c('section',{staticClass:"section d-flex flex-column justify-end justify-md-center"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"md":"6"}},[_c('h1',{staticClass:"mb-4 mb-sm-8 mb-md-12 font-weight-semibold text-h2 text-uppercase"},[_vm._v("\n              "+_vm._s(_vm.heading)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"mb-4 mb-sm-8 mb-md-12 text-h5"},[_vm._v("\n              "+_vm._s(_vm.description)+"\n            ")]),_vm._v(" "),_c(VBtn,{staticClass:"px-12 px-md-16",attrs:{"to":"/","title":"Return to the home page","depressed":"","light":"","rounded":"","x-large":""}},[_vm._v("\n              Home Page\n              "),_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(_vm.mdiArrowRight))])],1)],1)],1)],1),_vm._v(" "),_c('ImageBackground',{attrs:{"alt":"An astronaut on a black background.","src-mobile":"/images/404/background-mobile.jpg","src-desktop":"/images/404/background-desktop.jpg"}})],1)]),_vm._v(" "),_c('GlobalFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }