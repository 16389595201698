import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column justify-center",attrs:{"id":"contact","tag":"section"}},[_c(VCardTitle,{staticClass:"title mt-12 mt-sm-0 mr-sm-16"},[_c('h2',{staticClass:"mb-4 font-weight-semibold text-h4 text-sm-h3"},[_vm._v("\n      Partner with us"),_c('br',{staticClass:"d-none d-sm-block"}),_vm._v("\n      on your next mission\n    ")])]),_vm._v(" "),_c(VCardText,[_c('ContactForm')],1),_vm._v(" "),_c(VCardActions,{staticClass:"close"},[_c(VBtn,{attrs:{"icon":"","x-large":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v(_vm._s(_vm.mdiClose))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }