//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowRight } from "@mdi/js";
import { computed } from "@nuxtjs/composition-api";
const __sfc_main = {};
__sfc_main.props = {
  error: {
    type: Object,
    default: () => ({})
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const heading = computed(() => {
    if (props.error.statusCode === 404) return "Not Found (404)";
    return props.error.statusCode;
  });
  const description = computed(() => {
    if (props.error.statusCode === 404) return "The page you’re looking for might have been removed or is temporarily unavailable.";
    return props.error.message;
  });
  return {
    mdiArrowRight,
    heading,
    description
  };
};

export default __sfc_main;
