//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";

export default {
  props: {
    brand: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mdiArrowLeft,
      mdiArrowRight,
      tabs: [
        {
          name: "jennair",
          label: "JennAir",
        },
        {
          name: "kitchenaid",
          label: "KitchenAid",
        },
        {
          name: "gladiator",
          label: "Gladiator",
        },
        {
          name: "bbqguys",
          label: "BBQGuys",
        },
        {
          name: "neato-robotics",
          label: "Neato",
        },
      ],
    };
  },
  mounted() {
    // Ensure correct initial slider state
    window?.setTimeout(() => window?.dispatchEvent(new Event("resize")), 500);
  },
};
