//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowRight, mdiClose } from "@mdi/js";

export default {
  data() {
    return {
      mdiArrowRight,
      mdiClose,

      rules: {
        required: true,
        email: true,
      },

      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      company: "",
      message: "",

      submitting: false,
      submissionSuccess: false,
      validationError: false,
      submissionError: false,
    };
  },
  methods: {
    async submitEmail() {
      this.rules = {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
      };
      await this.$nextTick();
      if (!this.$refs.form.validate()) {
        this.validationError = true;
        return;
      }

      this.submitting = true;

      try {
        const response = await fetch(
          `https://4laz8w2zj1.execute-api.us-east-2.amazonaws.com/${process.env.SITE_ENV}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              jobTitle: this.jobTitle,
              company: this.company,
              message: this.message,
            }),
          }
        );

        const { Error } = await response.json();
        if (Error) {
          this.submitting = false;
          this.submissionError = true;
          return;
        }

        this.$refs.form.reset();
        this.submitting = false;
        this.submissionSuccess = true;
      } catch (error) {
        this.submitting = false;
        this.submissionError = true;
      }
    },
  },
};
